/*eslint-disable*/

import React from "react";
import { useNavigate } from "react-router-dom";

function StoryCard({ story, index }) {
	const navigate = useNavigate();
	function handleClick() {

		navigate(`${story.id}`);
	}
	console.log(story.id)
	return (
		<article
			key={index}
			className="flex flex-col rounded-xl shadow-lg bg-gradient-to-br from-white to-gray-50 p-6 transition-transform transform hover:-translate-y-2 hover:shadow-2xl duration-300 ease-in-out max-w-lg mx-auto sm:max-w-none sm:w-full text-center"
		>
			<button type="button" onClick={handleClick} className="w-full focus:outline-none">
				{/* Image Section */}
				<div className="relative w-full overflow-hidden rounded-xl mb-4">
					<img
						src={
							story.coverImage?.coverImage?.small?.url
								? story.coverImage.coverImage.small.url
								: "https://images.unsplash.com/photo-1605656816944-971cd5c1407f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=604&q=80"
						}
						alt=""
						className="w-full h-48 object-cover rounded-xl hover:scale-105 transition-transform duration-300 ease-in-out"
					/>
					<div className="absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-200" />
				</div>

				{/* Content Section */}
				<div className="flex flex-col items-center">
					{/* Org Name */}
					<div className="flex items-center justify-center gap-2">
						<span className="inline-block bg-gradient-to-r from-purple-400 to-indigo-500 text-white text-xs font-semibold px-3 py-1 rounded-full shadow hover:from-purple-500 hover:to-indigo-600 transition-colors duration-300">
							{story.orgName}
						</span>
					</div>

					{/* Blog Title */}
					<h3 className="mt-4 text-xl font-bold leading-snug text-gray-900 hover:text-indigo-600 transition-colors duration-300">
						{story.blogTitle}
					</h3>

					{/* Feedback/Description */}
					<p className="mt-2 text-gray-700 text-sm leading-relaxed line-clamp-3 max-w-xs">
						{story.prompts.feedback}
					</p>

					{/* Author Info */}
					<div className="mt-6 flex items-center justify-center">
						{/* Optional: Avatar Image */}
						{/* Uncomment this if you want to show an avatar */}
						{/* <img
          src="https://media.istockphoto.com/id/1386479313/photo/happy-millennial-afro-american-business-woman-posing-isolated-on-white.jpg?b=1&s=170667a&w=0&k=20&c=ahypUC_KTc95VOsBkzLFZiCQ0VJwewfrSV43BOrLETM="
          alt=""
          className="h-10 w-10 rounded-full bg-gray-100 mr-3"
        /> */}

						{/* Author Details */}
						<div className="text-center">
							<p className="text-sm font-semibold text-gray-900">
								{story.firstName} {story.lastName}
							</p>
							<p className="text-xs text-gray-500">{story.email}</p>
						</div>
					</div>
				</div>
			</button>
		</article>

	);
}

export default StoryCard;
