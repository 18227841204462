/* eslint-disable no-console */
import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function updateApplication(accessToken, payload, applicationId) {
    const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/scholarshipapp/${applicationId}`;
    const response = await makeAPIRequest(API_ENDPOINT, "PUT", payload, accessToken);
    const jsonResponse = await response.json();
    return jsonResponse;
}

  

export default updateApplication;
