/* eslint-disable  */
import React from "react";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import { PhotoIcon } from "@heroicons/react/24/solid";
import createImpactStory from "./createImpactStory";
import notify from "../../../services/toast";
import useUserContext from "../../../hooks/useUserContext";
import { MAGIC_NUMBERS } from "../../../constant";
import FileUploader from "../../../components/file_uploader/FileUploader";
import { MdLocalSee, MdDeleteOutline } from "react-icons/md";

function PhotoDisplay({ callback }) {
	return (
		<MdLocalSee
			onClick={callback}
			className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer"
		/>
	);
}

function ImpactSurvey() {
	const { userState } = useUserContext();
	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [orgName, setOrgName] = React.useState("");
	const [support, setSupport] = React.useState("");
	const [amountUsed, setAmountUsed] = React.useState("");
	const [howWeHelped, setHowWeHelped] = React.useState("");
	const [metrics, setMetrics] = React.useState("");
	const [feedback, setFeedback] = React.useState("");
	const [blogTitle, setBlogTitle] = React.useState("");
	const [orgDescription, setOrgDescription] = React.useState("");
	const [coverImage, setCoverImage] = React.useState("");
	const [storyImages, setStoryImages] = React.useState([]);
	const [socialMediaLink, setSocialMediaLink] = React.useState(""); // New state for social media link
	const [submitting, setSubmitting] = React.useState(false);
	const [submissionSuccess, setSubmissionSuccess] = React.useState(false); // New state for submission success

	const imageCriteria = { requiredSize: 6e6, requiredDimensions: { width: 400, height: 400 } };

	const handleUpload = (result) => {
		setCoverImage({
			coverImage: result,
		});
	};

	const handleImageUpload = (result) => {
		setStoryImages((prevState) => {
			return {
				...prevState,
				storyImages: [...(prevState?.storyImages || []), { ...result, type: "image" }],
			};
		});
	};

	const handleDeleteImage = (idx) => {
		setStoryImages((prevState) => {
			return {
				...prevState,
				storyImages: (prevState?.storyImages || []).filter((_, index) => index !== idx),
			};
		});
	};

	const handleSurveySubmit = async (event) => {
		event.preventDefault();
		try {
			setSubmitting(true);
			const payload = {
				blogTitle,
				firstName,
				lastName,
				email,
				orgName,
				orgDescription,
				storyImages,
				coverImage,
				socialMediaLink, // Include social media link in payload
				prompts: {
					support: support,
					amountUsed: amountUsed,
					howWeHelped: howWeHelped,
					metrics: metrics,
					feedback: feedback,
				},
			};
			const response = await createImpactStory(payload, userState.accessToken);
			if (response.status === "success") {
				setSubmissionSuccess(true); // Set submission success
				notify("Story submitted successfully");
			} else if (response.status === "fail") {
				setSubmitting(false);
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			setSubmitting(false);
			notify("Something went wrong. Please try again later");
		}
	};

	return (
		<>
			<div className="flex items-center justify-center min-h-screen bg-gray-100">
				<div className="bg-gradient-to-b from-primary-50 to-white rounded-md mt-5 pt-5 pb-10 text-center max-w-3xl w-full mx-4">
					<h1 className="mb-5 text-orange-700 font-bebas text-3xl animate-bounce">
						✍️ Tell Us How Your Year at COhatch Went
					</h1>

					{submissionSuccess ? (
						<div className="thank-you-message mx-4 sm:mx-8 p-8 mt-5 bg-green-50 border-l-4 border-green-500 rounded-lg shadow-lg text-green-900">
							<h2 className="text-3xl font-semibold mb-3">Thank You!</h2>
							<p className="text-lg">Your application and impact story have been submitted successfully.</p>
							{/* <p className="mt-2 italic">We appreciate your contribution!</p> */}

							<div className="mt-5">
								<a href="/dashboard" className="inline-block px-6 py-2 bg-success-800 hover:bg-success-50 text-white font-semibold rounded-lg transition duration-200">
									Back to Dashboard
								</a>
							</div>
						</div>

					) : (
						<form onSubmit={handleSurveySubmit}>
							<h1 className="mb-5 font-bold text-3xl text-success-800">We'd love to hear from you!</h1>
							<p className="text-black mx-4">
								Every year, we want to know how we helped you achieve your mission. Fill out this questionnaire and drop a story to move onto the next step of the scholarship process. For those renewing your scholarship, this is a required step in order to be renewed for the next scholarship year.
							</p>

							<div className="mx-6 lg:max-w-md lg:mx-auto mt-6">
								<div className="text-left max-w-lg mx-auto">
									<div className="grid gap-4">

										{/* Title */}
										<div className="flex flex-col">
											<label htmlFor="blogTitle" className="block text-lg font-medium text-gray-700">📖 Title</label>
											<p className="text-sm text-red-500 text-right">*</p>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 sm:text-md"
												type="text"
												name="blogTitle"
												id="blogTitle"
												value={blogTitle}
												onChange={(event) => setBlogTitle(event.target.value)}
											/>
										</div>

										<div className="grid grid-cols-2 gap-4">
											<div className="flex flex-col">
												<label htmlFor="firstName" className="block text-lg font-medium text-gray-700">👤 First name</label>
												<p className="text-sm text-red-400 text-right">*</p>
												<input
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 sm:text-md"
													type="text"
													name="firstName"
													id="firstName"
													value={firstName}
													onChange={(event) => setFirstName(event.target.value)}
												/>
											</div>

											<div className="flex flex-col">
												<label htmlFor="lastName" className="block text-lg font-medium text-gray-700">👤 Last name</label>
												<p className="text-sm text-red-400 text-right">*</p>
												<input
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 sm:text-md"
													type="text"
													name="lastName"
													id="lastName"
													value={lastName}
													onChange={(event) => setLastName(event.target.value)}
												/>
											</div>
										</div>

										{/* Email */}
										<div className="flex flex-col">
											<label htmlFor="email" className="block text-lg font-medium text-gray-700">📧 Email</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 sm:text-md"
												type="text"
												name="email"
												id="email"
												value={email}
												onChange={(event) => setEmail(event.target.value)}
											/>
										</div>

										{/* Organization Name */}
										<div className="flex flex-col mt-5">
											<label htmlFor="orgName" className="block text-lg font-medium text-gray-700">🏢 Your Organization Name</label>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 sm:text-md"
												type="text"
												name="orgName"
												id="orgName"
												value={orgName}
												onChange={(event) => setOrgName(event.target.value)}
											/>
										</div>

										{/* Organization Description */}
										<div className="flex flex-col">
											<label htmlFor="orgDescription" className="block text-lg font-medium text-gray-700">📝 Brief description of your organization</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 sm:text-md"
												type="text"
												name="orgDescription"
												id="orgDescription"
												value={orgDescription}
												onChange={(event) => setOrgDescription(event.target.value)}
											/>
										</div>

										{/* Support Description */}
										<div className="flex flex-col mt-5">
											<label htmlFor="support" className="block text-lg font-medium text-gray-700">🤝 How did you use COhatch this year to support your organization?</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 sm:text-md"
												type="text"
												name="support"
												id="support"
												value={support}
												onChange={(event) => setSupport(event.target.value)}
											/>
										</div>

										{/* Usage Count */}
										<div className="flex flex-col">
											<label htmlFor="amountUsed" className="block text-lg font-medium text-gray-700">🔢 How many times did you use a COhatch location this year?</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 sm:text-md"
												type="text"
												name="amountUsed"
												id="amountUsed"
												value={amountUsed}
												onChange={(event) => setAmountUsed(event.target.value)}
											/>
										</div>

										{/* Metrics */}
										<div className="flex flex-col">
											<label htmlFor="metrics" className="block text-lg font-medium text-gray-700">📊 Did COhatch help you create connections, receive funding, or any other related metrics this year?</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 sm:text-md"
												type="text"
												name="metrics"
												id="metrics"
												value={metrics}
												onChange={(event) => setMetrics(event.target.value)}
											/>
										</div>

										{/* How We Helped */}
										<div className="flex flex-col">
											<label htmlFor="howWeHelped" className="block text-lg font-medium text-gray-700">💡 Please add details related to how we helped with your network, funding/investment, etc. here at COhatch.</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<textarea
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 sm:text-md"
												name="howWeHelped"
												id="howWeHelped"
												value={howWeHelped}
												onChange={(event) => setHowWeHelped(event.target.value)}
											/>
										</div>

										{/* Feedback */}
										<div className="flex flex-col pt-5">
											<label htmlFor="feedback" className="block text-lg font-medium text-gray-700">🗨️ What would you say about COhatch and this scholarship? Please be descriptive.</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<textarea
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 sm:text-md"
												name="feedback"
												id="feedback"
												value={feedback}
												onChange={(event) => setFeedback(event.target.value)}
											/>
										</div>

										{/* Social Media Link */}
										<div className="flex flex-col pt-5">
											<label htmlFor="socialMediaLink" className="block text-lg font-medium text-gray-700">🌐 Share your social media link where you mentioned COhatch (optional)</label>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 sm:text-md"
												type="url"
												name="socialMediaLink"
												id="socialMediaLink"
												value={socialMediaLink}
												onChange={(event) => setSocialMediaLink(event.target.value)}
											/>
										</div>

										{/* Story Images Section */}
										<div className="mt-10 text-center">
											<h3 className="text-lg font-medium leading-6 text-success-800">Story Images</h3>
										</div>

										{/* Cover Image */}
										<div className="flex flex-col">
											<label className="block text-sm font-medium text-gray-700 mb-1">Cover Image</label>
											<span className="w-2 h-2 rounded-full bg-red-500"></span>
											<div className="relative border-4 border-dashed rounded-md h-[200px] w-full max-w-[200px] mx-auto">
												{coverImage.coverImage?.small.url && (
													<img src={coverImage.coverImage?.small.url} className="w-full h-full" alt="story cover image upload" />
												)}
												<FileUploader
													fileType="image/*"
													isMultiple={false}
													validationCriteria={imageCriteria}
													DisplayIcon={PhotoDisplay}
													accessToken={userState.accessToken}
													folder="story_photos"
													description="Image - Min: 400 X 400, Max: 5MB"
													numberOfFiles={1}
													callback={handleUpload}
												/>
											</div>
										</div>

										{/* Story Images */}
										<div className="flex flex-col mt-4">
											<label className="block text-sm font-medium text-gray-700">Story Images</label>
											<p className="mt-1 text-sm text-gray-500">You can upload up to 5 images (optional)</p>
											<div className="flex mb-8 mt-2">
												{storyImages.storyImages?.map((image, index) => (
													<div key={image.small.url} className="mr-2 w-24 text-center">
														<img src={image.small.url} className="w-full h-full" alt="property image" />
														<div className="text-center">
															<MdDeleteOutline className="mt-1 text-red-400 cursor-pointer mx-auto" onClick={() => handleDeleteImage(index)} />
														</div>
													</div>
												))}
											</div>
											<div className="relative border-4 border-dashed rounded-md h-[200px] w-full max-w-[200px] mx-auto">
												<FileUploader
													fileType="image/*"
													isMultiple={true}
													validationCriteria={imageCriteria}
													DisplayIcon={PhotoDisplay}
													className="text-2xl text-primary-400 cursor-pointer"
													accessToken={userState.accessToken}
													folder="story_photos"
													description="400 X 400 8MB Image Minimum. You can add up to 5 photos."
													numberOfFiles={5}
													callback={handleImageUpload}
												/>
											</div>
										</div>
									</div>

									{/* Submit Button */}
									<div className="flex justify-center mt-6">
										<ButtonPrimary text={submitting ? "Submitting..." : "Submit"} type="submit" disabled={submitting} />
									</div>
								</div>
							</div>
						</form>
					)}
				</div>
			</div>
		</>
	);
}

export default ImpactSurvey;
