/* eslint-disable */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import updateApplication from "./updateApplication";
import useUserContext from "../../hooks/useUserContext";

export default function BoostScholarshipLandingPage({ applicationId, onApplicationUpdated, reapplicant }) {
    // State to track whether the checkbox is checked
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const [error, setError] = useState(null); // Track any error messages

    const {
        userState: { accessToken },
    } = useUserContext();

    const navigate = useNavigate(); // Initialize the useNavigate hook

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Ensure applicationId is defined
        console.log("Submitting with Application ID:", applicationId);

        const payload = {
            termsAccepted: true, // Set to true (or "yes" if needed)
        };

        try {
            setIsLoading(true); // Start loading
            setError(null); // Clear previous errors

            // Update the application with the payload
            const response = await updateApplication(accessToken, payload, applicationId);
            
            console.log("Updated application:", response);

            // If the update is successful, trigger the callback
            if (onApplicationUpdated) {
                onApplicationUpdated(response.data);
            }

            // Conditional navigation based on reapplicant status
            if (reapplicant === "no") {
                navigate("/application_success");
            } else {
                navigate("/dashboard/volunteer/impact_stories/add");
            }
        } catch (error) {
            console.error("Error updating application:", error);
            setError("An error occurred while updating the application. Please try again.");
        } finally {
            setIsLoading(false); // Stop loading after the process
        }
    };


    return (
    <div className="bg-backcolor-50 p-8 sm:p-12 lg:p-20 font-roboto">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-primary-600 text-4xl lg:text-5xl font-bebas text-center mb-8">
          Updates to COhatch Boost Scholarship Program
        </h1>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          Thank you for being a BOOST Scholar here at COhatch. We know that successful startups need the support and engagement of the community around them to succeed.
        </p>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          As we move into the second year of utilizing our new scholarship application platform, we are making some changes to ensure we are able to continue our startup scholarships.
        </p>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          If you are currently renewing your scholarship, there are some changes that we are instituting to ensure we are allowing access to startups who need our space.
        </p>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          Here is a quick breakdown of what our BOOST (startup) Scholarships will look like moving forward*:
        </p>
        <ul className="text-secondary-700 text-lg mb-6 leading-relaxed list-disc list-inside">
          <li>Year 1: 90% off monthly coworking membership per user</li>
          <li>Year 2: 75% off monthly coworking membership per user</li>
          <li>Year 3: 50% off monthly coworking membership per user</li>
        </ul>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          *Pricing is subject to change year over year as we evaluate the economic status around us.
        </p>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          After Year 3, we will work with you to understand your business model and success to determine next steps for your COhatch membership.
        </p>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          We know this is a change from our previous years of scholarship, but our goal is to give you a boost to get started with us! We want to make sure you continue to get the great benefits of COhatch as your business continues to grow.
        </p>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          By submitting your scholarship renewal application and hitting submit here on this page, you will be accepting the new scholarship structure on behalf of your organization.
        </p>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          If you have questions about your scholarship or payment, please direct them to{" "}
          <a href="mailto:hello@cohatch.com" className="text-primary-400 underline">
            hello@cohatch.com
          </a>
          . For those who submit and are successfully renewed by the local team, you will hear from your Community Manager soon to get setup with the administrative fee going forward.
        </p>
        <p className="text-secondary-700 text-lg mb-6 leading-relaxed">
          Thank you for being a part of the COhatch community! We’re excited to continue to grow our scholarship programs with your help.
        </p>

        {/* Checkbox for terms and conditions */}
        <div className="mb-6">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            className="mr-2"
                            checked={isChecked}
                            onChange={(e) => setIsChecked(e.target.checked)}
                        />
                        <span className="text-secondary-700 text-lg">
                            I accept the terms and conditions.
                        </span>
                    </label>
                </div>

                {/* Submit button */}
                <button
                    className={`bg-primary-500 hover:bg-primary-600 text-white font-bold py-3 px-6 rounded w-full sm:w-auto block mx-auto ${!isChecked || isLoading ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                    onClick={handleSubmit}
                    disabled={!isChecked || isLoading} // Disable button if checkbox is not checked or loading
                >
                    {isLoading ? "Acceopting..." : "Accept Terms"}
                </button>

                {error && <p className="text-red-600 mt-4">{error}</p>} {/* Display any error messages */}
            </div>
        </div>
    );
}
