/*eslint-disable*/
import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";
import getAppsById from "./requests/getAppsByUserId";
import submitApplication from "../philanthropy/give_scholarships/submitApplication";
import { Input, SelectInputs } from "../../components/formvalidate/Input";
import { MAGIC_NUMBERS, APP_MARKETS, APP_LOCATIONS } from "../../constant";
import GiveScholarshipLandingPage from "../../components/app_terms_landing_page/GiveScholarshipLandingPage";
import BoostScholarshipLandingPage from "../../components/app_terms_landing_page/BoostScholarshipLandingPage";

function ReapplicationPage() {
	const { userState } = useUserContext();
	const accessToken = userState.accessToken;
	const userId = userState.id;
	const [apps, setApps] = React.useState([]);
	const [selectedApp, setSelectedApp] = React.useState(null);
	const [isChecked, setIsChecked] = React.useState(false);
	const [submitting, setSubmitting] = React.useState(false);
	const [newAppId, setNewAppId] = React.useState(null); // State to store the new application ID
	const navigate = useNavigate();

	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			phoneNumber: "",
			orgName: "",
			website: "",
			businessAddress: "",
			businessVideo: "",
			businessPlan: "",
			market: "",
			coLocation: "",
			orgAmount: "",
			breakevenPoint: "",
			planHire:"",
			locationUsage: "", // New field
			threeChallenges: "", // New field
			funding: "", // New field
			annualEvents: "", // New field
			volunteers: "", // New field
		},
	});

	const scorecardOPTIONS = [
		{ value: 1, label: "1" },
		{ value: 2, label: "2" },
		{ value: 3, label: "3" },
		{ value: 4, label: "4" },
		{ value: 5, label: "5" },
	];

	const { reset, formState: { isValid, errors } } = methods;

	const getTenAndHalfMonthsAgo = () => {
		const today = new Date();
		// Set the date 10 months ago
		today.setMonth(today.getMonth() - 10);
		// Subtract half a month (approximately 15 days) from that date
		today.setDate(today.getDate() - 15);
		return today;
	};
	
	const fetchApplicationByUserId = async (accessToken, userId) => {
		try {
			const response = await getAppsById(accessToken, userId);
			if (response.status === "success") {
				const tenAndHalfMonthsAgo = getTenAndHalfMonthsAgo();
				const filteredApps = response.data.filter(app => {
					const createdAt = new Date(app.createdAt);

					return createdAt <= tenAndHalfMonthsAgo; // Show apps created before or on 10.5 months ago

				});
				setApps(filteredApps);
			} else {
				console.log("Error fetching applications");
			}
		} catch (error) {
			console.error("Error fetching apps: ", error);
		}
	};

	const generateCohatchMarket = () => {
		const cohatchMarket = [];
		for (const market in APP_MARKETS) {
			cohatchMarket.push(APP_MARKETS[market]);
		}
		return cohatchMarket.flat();
	};

	const generateCohatchLocation = () => {
		if (methods.watch().market) {
			const cohatchLocation = [];
			cohatchLocation.push(APP_LOCATIONS[methods.watch().market]["locations"]);
			return cohatchLocation.flat();
		} else {
			return [];
		}
	};

	const handleOnChange = (app) => {
		setSelectedApp(app);
		setIsChecked(true);
		reset({
			firstName: app.firstName || "",
			lastName: app.lastName || "",
			email: app.email || "",
			phoneNumber: app.phoneNumber || "",
			orgName: app.orgName || "",
			website: app.website || "",
			businessAddress: app.businessAddress || "",
			businessVideo: app.businessVideo || "",
			market: app.market || "",
			coLocation: app.coLocation || "",
			orgAmount: app.orgAmount || "",
			locationUsage: app.locationUsage || "", // New field
			threeChallenges: app.threeChallenges || "", // New field
			funding: app.funding || "", // New field
			annualEvents: app.annualEvents || "", // New field
			volunteers: app.volunteers || "", // New field
			businessPlan: app.businessPlan || "", // New field
			breakevenPoint: app.breakevenPoint || "", // New field
			planHire: app.planHire || "", // New field
			
		});
	};

	const onSubmit = methods.handleSubmit(async (data) => {
		// Check if form is valid before submitting
		if (!isValid) return;

		await handleFormSubmit(data);
	});

	const handleFormSubmit = async (data) => {
		try {
			setSubmitting(true);

			// Create the payload and set statuses to "pending"
			const givePayload = {
				...selectedApp,
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
				phoneNumber: data.phoneNumber,
				orgName: data.orgName,
				website: data.website,
				businessAddress: data.businessAddress,
				businessVideo: data.businessVideo,
				market: data.market,
				coLocation: data.coLocation,
				orgAmount: data.orgAmount,
				businessPlan: data.businessPlan,
				breakevenPoint: data.breakevenPoint,
				planHire: data.planHire,
				locationUsage: data.locationUsage, // New field
				threeChallenges: data.threeChallenges, // New field
				funding: data.funding, // New field
				annualEvents: data.annualEvents, // New field
				volunteers: data.volunteers, // New field
				reapplicant: "yes",
				appStatus: "pending",  // Set appStatus to "pending"
				cmStatus: "pending",    // Set cmStatus to "pending"
				mlStatus: "pending",     // Set mlStatus to "pending"
				createdAt: new Date(),
			};

			const response = await submitApplication(givePayload, accessToken);
			if (response.status === "success") {
				setNewAppId(response.data.id); // Store the new application ID
				setSubmitting(false);
			} else {
				setSubmitting(false);
				let displayMessage = response.message || "An error occurred.";
				console.log(displayMessage);
			}
		} catch (error) {
			setSubmitting(false);
			console.error("Submission error: ", error);
		}
	};

	useEffect(() => {
		fetchApplicationByUserId(accessToken, userId);
	}, [accessToken, userId]);

	// Conditionally render the landing page based on the type of application
	if (newAppId) {
		return selectedApp?.type === "give" ? (
			<GiveScholarshipLandingPage applicationId={newAppId} />
		) : (
			<BoostScholarshipLandingPage applicationId={newAppId} />
		);
	}


	
	return (
		<div className="p-6 flex justify-center min-h-screen bg-gray-50">
			<div className="max-w-5xl w-full bg-white shadow-md rounded-lg overflow-hidden">
				<div className="bg-gradient-to-r from-success-800 to-success-700 p-8 text-white text-center rounded-t-lg">
					<h1 className="text-2xl font-bold">2024 Application</h1>
				</div>

				<div className="p-8 lg:px-16">
					{apps.length > 0 && !isChecked ? (
						<div className="space-y-4">
							{apps.map((app, index) => (
								<div key={index} className="flex items-center justify-between border-b py-4">
									<div className="flex flex-col">
										<span className="capitalize text-lg font-semibold">{app.orgName}</span>
										<span className="text-sm text-gray-600">{app.type}</span>
									</div>
									<button
										onClick={() => handleOnChange(app)}
										className="inline-flex items-center px-4 py-2 bg-success-600 text-white font-medium rounded-md shadow-sm hover:bg-success-700 transition duration-300 ease-in-out"
									>
										Select
									</button>
								</div>
							))}
						</div>
					) : null}

					{isChecked ? (
						<div className="mt-10 space-y-6">
							<h2 className="text-lg font-semibold text-gray-700 mb-5">
								Please double-check that all information is correct.
							</h2>

							<FormProvider {...methods}>
								<form onSubmit={onSubmit} noValidate autoComplete="off" className="space-y-6">
									<div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
										{/* First Name Field */}
										<div>
											<label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
												First Name
											</label>
											<Input
												name="firstName"
												validation={{
													required: {
														value: true,
														message: "required",
													},
												}}
												className="mt-1 block w-full"
											/>
											{errors.firstName && <p className="text-red-600 text-sm mt-1">{errors.firstName.message}</p>}
										</div>

										{/* Last Name Field */}
										<div>
											<label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
												Last Name
											</label>
											<Input
												name="lastName"
												validation={{
													required: {
														value: true,
														message: "required",
													},
												}}
												className="mt-1 block w-full"
											/>
											{errors.lastName && <p className="text-red-600 text-sm mt-1">{errors.lastName.message}</p>}
										</div>
									</div>

									<div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
										{/* Email Field */}
										<div>
											<label htmlFor="email" className="block text-sm font-medium text-gray-700">
												Email
											</label>
											<Input
												name="email"
												type="email"
												validation={{
													required: {
														value: true,
														message: "required",
													},
												}}
												className="mt-1 block w-full"
											/>
											{errors.email && <p className="text-red-600 text-sm mt-1">{errors.email.message}</p>}
										</div>

										{/* Phone Number Field */}
										<div>
											<label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
												Phone Number
											</label>
											<Input
												name="phoneNumber"
												type="tel"
												validation={{
													required: {
														value: true,
														message: "required",
													},
												}}
												className="mt-1 block w-full"
											/>
											{errors.phoneNumber && <p className="text-red-600 text-sm mt-1">{errors.phoneNumber.message}</p>}
										</div>
									</div>

									{/* Organization Name Field */}
									<div>
										<label htmlFor="orgName" className="block text-sm font-medium text-gray-700">
											Organization Name
										</label>
										<Input
											name="orgName"
											type="text"
											validation={{
												required: {
													value: true,
													message: "required",
												},
											}}
											className="mt-1 block w-full"
										/>
										{errors.orgName && <p className="text-red-600 text-sm mt-1">{errors.orgName.message}</p>}
									</div>

									{/* Organization Website Field */}
									<div>
										<label htmlFor="website" className="block text-sm font-medium text-gray-700">
											Organization Website
										</label>
										<Input
											name="website"
											type="url"
											validation={{
												required: {
													value: false,
												},
											}}
											className="mt-1 block w-full"
										/>
										{errors.website && <p className="text-red-600 text-sm mt-1">{errors.website.message}</p>}
									</div>

									{/* Business Address Field */}
									<div>
										<label htmlFor="businessAddress" className="block text-sm font-medium text-gray-700">
											Organization Address
										</label>
										<Input
											name="businessAddress"
											validation={{
												required: {
													value: true,
													message: "required",
												},
											}}
											className="mt-1 block w-full"
										/>
										{errors.businessAddress && <p className="text-red-600 text-sm mt-1">{errors.businessAddress.message}</p>}
									</div>

									<div className="w-full flex gap-3 mb-6">
										{/* Business Video */}
										<div className="w-full">
											<label className="font-semibold capitalize">
												Please record a 3 minute or less video talking about
												your business and why you'd like to be a part of the
												COhatch ecosystem. Please utilize a service like loom
												where you can record yourself and grab a link, for
												free! Loom:
												<a
													href="https://www.loom.com/signup"
													target="_blank"
													className="text-blue-900 underline lowercase"
												>
													https://www.loom.com/signup
												</a>
											</label>
											<span> (Please include links or provide a reason if a link cannot be added.)</span>
											<Input
												className=" appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
												type="input"
												name="businessVideo"
												placeholder="Link here..."
												id="businessVideo"
												validation={{
													required: {
														value: true,
													message: "required",
													},
													// pattern: {
													// 	value:
													// 		/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,255}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
													// 	message: "not a valid website",
													// },
												}}
											/>
											{errors.businessVideo && <p className="text-red-600 text-sm mt-1">{errors.businessVideo.message}</p>}
										</div>
									</div>


									<div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
										{/* COhatch City Select */}
										<div>
											<SelectInputs
												name="market"
												label="COhatch City"
												options={generateCohatchMarket()}
												placeholder={selectedApp.market}
												validation={{
													required: {
														value: true,
														message: "required",
													},
												}}
												className="mt-1 block w-full"
											/>
											{errors.market && <p className="text-red-600 text-sm mt-1">{errors.market.message}</p>}
										</div>

										{/* COhatch Location Select */}
										<div>
											<SelectInputs
												name="coLocation"
												label="COhatch Location"
												options={generateCohatchLocation()}
												placeholder={selectedApp.coLocation}
												validation={{
													required: {
														value: true,
														message: "required",
													},
												}}
												className="mt-1 block w-full"
											/>
											{errors.coLocation && <p className="text-red-600 text-sm mt-1">{errors.coLocation.message}</p>}
										</div>
									</div>

									{/* Employee Access Select */}
										<div className="w-full flex gap-3 mb-6">
											<div className="w-full">
												<SelectInputs
													label="How many employees need access to COhatch?"
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													name="orgAmount"
													id="orgAmount"
													options={scorecardOPTIONS}
													placeholder={selectedApp.orgAmount}
													setSelectedValue={(event) =>
														setOrgAmount(event.value)
													}
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
										</div>

									{/* Conditional Questions for Type "give" */}
									{selectedApp?.type === "give" && (
										<div className="space-y-6">
											{/* Location Usage Field */}
											<div>
												<label htmlFor="locationUsage" className="block text-sm font-medium text-gray-700">
													How Are You Planning To Use Our Location?
												</label>
												<Input
													name="locationUsage"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
													className="mt-1 block w-full"
												/>
												{errors.locationUsage && <p className="text-red-600 text-sm mt-1">{errors.locationUsage.message}</p>}
											</div>

											{/* threeChallenges Field */}
											<div>
												<label htmlFor="threeChallenges" className="block text-sm font-medium text-gray-700">
													What Are Your Organization's Top Three Challenges Or Barriers?
												</label>
												<Input
													name="threeChallenges"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
													className="mt-1 block w-full"
												/>
												{errors.threeChallenges && <p className="text-red-600 text-sm mt-1">{errors.threeChallenges.message}</p>}
											</div>

											{/* Funding Field */}
											<div>
												<label htmlFor="funding" className="block text-sm font-medium text-gray-700">
													How Is Your Organization Funded?
												</label>
												<Input
													name="funding"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
													className="mt-1 block w-full"
												/>
												{errors.funding && <p className="text-red-600 text-sm mt-1">{errors.funding.message}</p>}
											</div>

											{/* Annual Events Field */}
											<div>
												<label htmlFor="annualEvents" className="block text-sm font-medium text-gray-700">
													Does Your Organization Have Any Annual Events? If So, Please Tell Us About Your Events.
												</label>
												<Input
													name="annualEvents"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
													className="mt-1 block w-full"
												/>
												{errors.annualEvents && <p className="text-red-600 text-sm mt-1">{errors.annualEvents.message}</p>}
											</div>

											{/* Volunteers Field */}
											<div>
												<label htmlFor="volunteers" className="block text-sm font-medium text-gray-700">
													Does Your Organization Use Volunteers? If So, Tell Us How?
												</label>
												<Input
													name="volunteers"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
													className="mt-1 block w-full"
												/>
												{errors.volunteers && <p className="text-red-600 text-sm mt-1">{errors.volunteers.message}</p>}
											</div>
										</div>
									)}

									{/* Conditional Questions for Type "give" */}
									{selectedApp?.type === "boost" && (
										<div className="space-y-6">
											{/* Location Usage Field */}
											<div>
												<label htmlFor="locationUsage" className="block text-sm font-medium text-gray-700">
													How Are You Planning To Use Our Location?
												</label>
												<Input
													name="locationUsage"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
													className="mt-1 block w-full"
												/>
												{errors.locationUsage && <p className="text-red-600 text-sm mt-1">{errors.locationUsage.message}</p>}
											</div>

											{/* threeChallenges Field */}
											<div>
												<label htmlFor="threeChallenges" className="block text-sm font-medium text-gray-700">
													What Are Your Organization's Top Three Challenges Or Barriers?
												</label>
												<Input
													name="threeChallenges"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
													className="mt-1 block w-full"
												/>
												{errors.threeChallenges && <p className="text-red-600 text-sm mt-1">{errors.threeChallenges.message}</p>}
											</div>

											{/* businessPlan Field */}
											<div>
												<label htmlFor="businessPlan" className="block text-sm font-medium text-gray-700">
												Tell us about your business. Do you have a business plan you can share?
												</label>
												<Input
													name="businessPlan"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
													className="mt-1 block w-full"
												/>
												{errors.businessPlan && <p className="text-red-600 text-sm mt-1">{errors.businessPlan.message}</p>}
											</div>
											
											{/* breakevenPoint Field */}
											<div>
												<label htmlFor="breakevenPoint" className="block text-sm font-medium text-gray-700">
												When do you project your breakeven point?
												</label>
												<Input
													name="breakevenPoint"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
													className="mt-1 block w-full"
												/>
												{errors.breakevenPoint && <p className="text-red-600 text-sm mt-1">{errors.breakevenPoint.message}</p>}
											</div>

											{/* planHire Field */}
											<div>
												<label htmlFor="planHire" className="block text-sm font-medium text-gray-700">
													How many employees do you have? How many do you plan to hire in the next 12/24/36 months?
												</label>
												<Input
													name="planHire"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
													className="mt-1 block w-full"
												/>
												{errors.planHire && <p className="text-red-600 text-sm mt-1">{errors.planHire.message}</p>}
											</div>

										</div>
									)}

									{/* Buttons */}
									<div className="flex items-center justify-between mt-8">
										<button
											className="text-sm font-medium text-success-600 hover:text-success-700 focus:outline-none focus:underline"
											onClick={() => setIsChecked(false)}
											type="button"
										>
											Back
										</button>
										<button
											className="inline-flex items-center px-6 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-success-600 hover:bg-success-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-success-500 transition duration-300 ease-in-out"
											type="submit"
											disabled={!isValid || submitting} // Ensure the button is disabled if the form is invalid
										>
											Next
										</button>
									</div>
								</form>
							</FormProvider>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}

export default ReapplicationPage;
