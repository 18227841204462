/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Pagination from "../../../components/pagination/Pagination";

import { useNavigate } from "react-router-dom";
import { MAGIC_NUMBERS } from "../../../constant";
import { debounce } from "../../../services/util";
import useUserContext from "../../../hooks/useUserContext";
import notify from "../../../services/toast";
import StoryCard from "./StoryCard";
import getImpactStories from "./getImpactStories.js";

function ImpactStoryTimeline() {
	const [stories, setStories] = useState([]);
	const { userState } = useUserContext();
	const navigate = useNavigate();

	function renderStories(story, index) {
		return <StoryCard key={index} story={story} />;
	}

	function onClick() {
		navigate("add");
	}

	const [pagination, setPagination] = React.useState({
		total: 0,
		page: 0,
		remaining: 0,
		limit: 11,
	});

	const fetchImpactStories = async (accessToken, offset, limit) => {
		try {
			const response = await getImpactStories(
				{
					offset,
					limit,
				},
				accessToken
			);

			if (response.status === "success") {
				const { limit, page, remaining, total, results } = response.data;
				console.log(results);
				setPagination({ limit, page: page, remaining, total });
				setStories(results);
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			notify("something went wrong");
		} finally {
			console.log("error");
		}
	};

	const handlePrevFetch = async () => {
		await fetchApplications(
			userState.accessToken,
			pagination.page - 1,
			keyword
		);
	};

	const handleNextFetch = async () => {
		await fetchApplications(
			userState.accessToken,
			pagination.page + 1,
			keyword
		);
	};

	useEffect(() => {
		fetchImpactStories(userState.accessToken, 0, pagination.limit);
	}, []);

	return (
		<div className="relative bg-gradient-to-br from-emerald-100 via-white to-emerald-300 py-20 sm:py-4 sm:px-6">
			{/* Optional subtle pattern overlay */}
			<div className="absolute inset-0 bg-[url('https://www.transparenttextures.com/patterns/cubes.png')] opacity-10 pointer-events-none"></div>

			<div className="relative mx-auto max-w-7xl px-6 lg:px-8">
				{/* Header Section */}
				<div className="mx-auto max-w-3xl text-center">
					<h2 className="text-gray-900 text-4xl font-extrabold tracking-tight sm:text-5xl">
						Impact Stories
					</h2>
					<p className="mt-4 text-xl leading-relaxed text-gray-700">
						The Latest Impact Stories from our Nonprofits, Startups, and Members.
					</p>
				</div>

				{/* Search Bar & Add Button Section */}
				<div className="mt-8 flex flex-col sm:flex-row justify-center items-center gap-4">
					{/* Search Bar */}
					<input
						type="text"
						name="search"
						placeholder="Search Stories"
						className="w-full sm:w-1/2 max-w-lg border border-gray-300 rounded-lg shadow-sm px-4 py-2 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 transition-all duration-300 ease-in-out"
					/>
					{/* Add Story Button */}
					<button
						className="bg-orange-600 hover:bg-orange-700 text-white font-semibold py-3 px-6 rounded-lg shadow-md transition-transform transform hover:-translate-y-1 hover:shadow-lg duration-300"
						onClick={onClick}
						type="button"
					>
						Add Story
					</button>
				</div>

				{/* Stories Grid Section */}
				<div className="mx-auto mt-16 grid grid-cols-1 gap-y-12 gap-x-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
					{stories.map((story, index) => (
						renderStories(story, index)
					))}
				</div>
			</div>

			{/* Pagination Section */}
			<div className="mt-12 flex justify-center">
				<Pagination
					pagination={pagination}
					next={handleNextFetch}
					prev={handlePrevFetch}
				/>
			</div>
		</div>

	);
}

export default ImpactStoryTimeline;
