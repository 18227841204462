/* eslint-disable */
import React, { useState } from "react";
import useUserContext from "../../hooks/useUserContext";
import { MAGIC_NUMBERS, APP_MARKETS } from "../../constant";
import notify from "../../services/toast";
import submitCPInfo from "./submitCPInfo";
import Logo from "../../components/logo/Logo";
import SelectInput from "../../components/select_input/SelectInput";

function CommunityPartnerForm() {
	const { userState } = useUserContext();
	const [orgName, setOrgName] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [emailAddress, setEmailAddress] = useState("");
	const [cohatchUses, setCohatchUses] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [market, setMarket] = useState("");
	const [website, setWebsite] = useState("");
	const [orgMission, setOrgMission] = useState("");
	const [submitted, setSubmitted] = useState(false);

	const generateCohatchMarket = () => {
		const cohatchMarket = [];
		for (const market in APP_MARKETS) {
			cohatchMarket.push(APP_MARKETS[market]);
		}
		return cohatchMarket.flat();
	};

	const handleMarketChange = (event) => {
		if (event) {
			const { value } = event;
			setMarket(value);
		} else {
			setMarket("");
		}
	};

	const validateFields = () => {
		const errors = [];
		if (!orgName) errors.push("Organization Name");
		if (!firstName) errors.push("First Name");
		if (!lastName) errors.push("Last Name");
		if (!emailAddress) errors.push("Email Address");
		if (!phoneNumber) errors.push("Phone Number");
		if (!market) errors.push("COhatch Market");
		if (!orgMission) errors.push("Organization Mission");
		if (!cohatchUses) errors.push("How would you like to utilize COhatch?");

		return errors;
	};

	const onSubmit = async (event) => {
		event.preventDefault();

		const errors = validateFields();
		if (errors.length > 0) {
			notify(`Please fill in the following fields: ${errors.join(", ")}`, "error");
			return;
		}

		try {
			const payload = {
				orgName,
				firstName,
				lastName,
				phoneNumber,
				cohatchUses,
				emailAddress,
				market,
				website,
				orgMission,
			};
			const response = await submitCPInfo(payload);
			if (response.status === "success") {
				setSubmitted(true);
			} else {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage = "An error has occurred. Please try again later.";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			notify("Unable to submit form. Please try again later");
		}
	};

	if (submitted) {
		return (
			<div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-8 px-6">
				<div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
					<h1 className="text-3xl font-bold text-green-600 mb-4">Thank You!</h1>
					<p className="text-lg leading-relaxed mb-4">
						Your information has been submitted successfully. A Market Leader will review your submission and reach out to you shortly.
					</p>
				</div>
			</div>
		);
	}

	return (
		<div className="min-h-screen bg-gray-100 flex flex-col">
			<header className="bg-green-600">
				<nav className="mx-auto max-w-7xl px-6 lg:px-8" aria-label="Top">
					<div className="flex w-full items-center justify-center py-6">
						<Logo color="white" />
					</div>
				</nav>
			</header>

			<div className="flex flex-col items-center justify-center flex-1 bg-gray-100 py-8 px-6">
				<div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-2xl">
					<h1 className="text-3xl font-bold text-green-600 mb-4 text-center">Community Partner Invite</h1>
					<p className="text-lg leading-relaxed mb-4 text-center">
					You've been invited to join COhatch as a Community Partner! Please enter and submit your information so a Market Leader can review and get you started on next steps.
					</p>
					<h2 className="text-xl font-bold mb-2 text-center">What is a Community Partner?</h2>
					<p className="text-lg leading-relaxed mb-6 text-center">
					A Community Partner is an organization COhatch chooses to be a part of our community. Their missions align with COhatch's mission. Being a Partner will give you access to one of our locations at a discounted rate to use our co-working spaces and meeting rooms.
					</p>
					<form onSubmit={onSubmit} noValidate autoComplete="off" className="space-y-6">
						<div>
							<label className="block font-bold mb-2 text-gray-700" htmlFor="orgName">Organization Name</label>
							<input
								className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
								name="orgName"
								type="text"
								id="orgName"
								placeholder="Organization Name"
								onChange={(e) => setOrgName(e.target.value)}
								required
							/>
						</div>
						<div className="flex flex-col gap-6 md:flex-row md:gap-6">
							<div className="flex-1">
								<label className="block font-bold mb-2 text-gray-700" htmlFor="firstName">First Name</label>
								<input
									className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
									name="firstName"
									type="text"
									id="firstName"
									placeholder="First Name"
									onChange={(e) => setFirstName(e.target.value)}
									required
								/>
							</div>
							<div className="flex-1">
								<label className="block font-bold mb-2 text-gray-700" htmlFor="lastName">Last Name</label>
								<input
									className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
									name="lastName"
									type="text"
									id="lastName"
									placeholder="Last Name"
									onChange={(e) => setLastName(e.target.value)}
									required
								/>
							</div>
						</div>
						<div className="flex flex-col gap-6 md:flex-row md:gap-6">
							<div className="flex-1">
								<label className="block font-bold mb-2 text-gray-700" htmlFor="emailAddress">Email Address</label>
								<input
									className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
									name="emailAddress"
									type="email"
									id="emailAddress"
									placeholder="Email Address"
									onChange={(e) => setEmailAddress(e.target.value)}
									required
								/>
							</div>
							<div className="flex-1">
								<label className="block font-bold mb-2 text-gray-700" htmlFor="phoneNumber">Phone Number</label>
								<input
									className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
									name="phoneNumber"
									type="tel"
									id="phoneNumber"
									placeholder="Phone Number"
									onChange={(e) => setPhoneNumber(e.target.value)}
									required
								/>
							</div>
						</div>
						<div className="flex flex-col gap-6 md:flex-row md:gap-6">
							<div className="flex-1">
								<label className="block font-bold mb-2 text-gray-700" htmlFor="market">Which COhatch Market are you interested in?</label>
								<SelectInput
									label="COhatch City"
									name="market"
									id="market"
									options={generateCohatchMarket()}
									noOptionsMessage="No cities found"
									placeholder="Select a COhatch City"
									setSelectedValue={handleMarketChange}
									required
								/>
							</div>
							<div className="flex-1">
								<label className="block font-bold mb-2 text-gray-700" htmlFor="website">Website</label>
								<input
									type="text"
									name="website"
									id="website"
									className="appearance-none block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
									placeholder="Enter website"
									onChange={(e) => setWebsite(e.target.value)}
								/>
							</div>
						</div>
						<div>
							<label className="block font-bold mb-2 text-gray-700" htmlFor="orgMission">Description of your organization and mission?</label>
							<textarea
								className="appearance-none block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
								name="orgMission"
								id="orgMission"
								placeholder="Description here..."
								onChange={(e) => setOrgMission(e.target.value)}
								rows="3"
								required
							/>
						</div>
						<div>
							<label className="block font-bold mb-2 text-gray-700" htmlFor="cohatchUses">How would you like to utilize COhatch?</label>
							<textarea
								className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
								name="cohatchUses"
								id="cohatchUses"
								placeholder="Enter Response"
								onChange={(e) => setCohatchUses(e.target.value)}
								rows="3"
								required
							></textarea>
						</div>
						<div className="flex justify-center mt-4">
							<button
								className="px-5 py-2 border border-transparent text-md font-medium rounded-lg shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition ease-linear duration-300"
								type="submit"
							>
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default CommunityPartnerForm;
