
import React from "react";
import { GiPiggyBank } from "react-icons/gi";
import { IoSettingsOutline } from "react-icons/io5";
import { ImClock } from "react-icons/im";
import MeshCover from "../../assets/MeshCover.jpg";
import Med1 from "../../assets/Med1.jpg";
import Med2 from "../../assets/Med2.png";
import Med3 from "../../assets/Med3.png";
import CoreLife from "../../assets/CoreLife.png";

export default function HealthAndWellness() {
  return (
    <>
      <main>
        {/* Hero section */}
          <div className="mx-auto mt-8 max-w-7xl sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src={MeshCover}
                  alt="People working on laptops"
                />
                <div className="absolute inset-0 bg-black opacity-50 mix-blend-multiply" />
              </div>
              <div className="relative py-16 px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="text-center text-4xl font-bold font-bebas tracking-wider sm:text-5xl lg:text-6xl">
                  <span className="block text-primary-400 ">HEALTH BENEFITS with</span>

                  <div className="">
                    <img className="mx-auto mt-4 h-24 w-36" src={CoreLife} alt=""></img>
                  </div>
                </h1>

                <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center">
                  <div className="space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-1 sm:gap-5 sm:space-y-0">
                    <a
                      href="https://calendly.com/jackreinoehl/benefits-overview"
                      className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-primary-400 shadow-sm hover:bg-primary-50 sm:px-8"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
     

        {/* Alternating Feature Sections */}
        <div className="relative overflow-hidden pt-16 pb-2">
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
          <div className="relative">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
              <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-2 lg:px-0">
                <div>
                  <div>
                    <h2 className="text-3xl font-bold font-bebas tracking-wide text-success-800">Simple Health Insurance, Made for You.</h2>
                    <p className="mt-4 text-lg text-gray-500">
                      COhatch and CoreLife Benefits have come together to offer you health insurance and employee benefits!
                    </p>
                    <p className="mt-4 text-lg text-gray-500">
                      You can now find cost effective coverage for you or your employees using A.I. Underwriting and top carriers without lengthy health questionnaires or clunky processes.
                    </p>
                    <div className="mt-6">
                      <a
                        href="https://calendly.com/jackreinoehl/benefits-overview"
                        target="_blank" rel="noreferrer"
                        className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-primary-400 to-primary-400 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-primary-100 hover:to-primary-100"
                      >
                        Schedule a Consultation
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="-mr-48 pl-6  lg:relative lg:m-0 lg:h-full lg:px-0">
                  <img
                    className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md"
                    src={Med1}
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <div className="mx-auto max-w-7xl overflow-hidden py-16 px-4 sm:py-12 sm:px-6 lg:px-8">
            <div className="text-center">
              <p className="mt-1 mb-8 text-4xl font-bold font-bebas tracking-wide text-primary-400 sm:text-5xl lg:text-5xl">
                Why are we offering Health Benefits?
              </p>
              <p>At COhatch, we know how hard it is to find great benefits at affordable rates. We started our business with the idea that health and family come first, but finding rates that were affordable for our small team was difficult. CoreLife helped us find customizable insurance options by navigating the complicated health marketplace for us. Our goal by offering health benefits is to help you do the same: get the help you need to find the best coverage and rates for what you need, make it easy to get you and your team setup with benefits, and over time, get better rates for everyone that's a part of the COhatch community.  </p>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <div className="mt-4 mb-4 mx-auto max-w-7xl overflow-hidden py-16 px-4 sm:py-12 sm:px-6 lg:px-8">
            <div className="text-center">
              <p className="mt-4 text-4xl font-bold font-bebas tracking-wide text-primary-400 sm:text-5xl lg:text-5xl">
                Get Your custom health benefits quote in 48 hours!**
              </p>
              <p className="mx-auto mt-5 max-w-xl text-xl text-gray-500">
                Just three easy steps to get a quote...
              </p>
            </div>
          </div>
        </div>

        <div className="bg-primary-400">
          <div className="mx-auto max-w-7xl py-12 px-6 lg:flex lg:items-center lg:justify-between lg:py-6 lg:px-8">
            <div className="sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:text-left">
              <h1>
                <span className="text-4xl font-bold font-bebas tracking-wide text-gray-900 sm:text-2xl lg:text-3xl">
                  <span className="block text-success-800">1. Schedule a consultation</span>
                </span>
              </h1>
              <p className="mt-3 text-base text-white sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                Schedule a Benefits Consultation to identify your needs, employee concerns, and questions.
              </p>
              <div className="mt-6">
                <a
                  href="https://calendly.com/jackreinoehl/benefits-overview"
                  target="_blank" rel="noreferrer"
                  className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-success-800 to-success-800 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-success-50 hover:to-success-50"
                >
                  Schedule Your Consultation
                </a>
              </div>
            </div>
            <div className="relative mt-12 sm:mx-auto sm:max-w-lg lg:col-span-6 lg:mx-0 lg:mt-0 lg:flex lg:max-w-none lg:items-center">
              <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                <img
                  className="w-full"
                  src={Med3}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-50">
          <div className="mx-auto max-w-7xl py-12 px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
            <div className="max-w-xl">
              <h2 className="text-4xl font-bold font-bebas tracking-wide text-success-800 sm:text-2xl lg:text-3xl">2. Send us Your Census</h2>
              <p className="mt-5 text-xl text-gray-500">
                Before your consultation, fill out your group’s Census information. Download the Excel template and upload OR fill out the information in our portal!
              </p>
              <div className="mt-6">
                <div className="inline-flex rounded-md shadow">
                  <a
                    href="https://www.cohatch.com/wp-content/uploads/2023/01/Census-Data-Template-CoreLife-Benefits-012023.xlsx"
                    target="_blank" rel="noreferrer"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-5 py-3 text-base font-medium text-white hover:bg-primary-700"
                  >
                    Download Excel Template
                  </a>
                </div>
                <div className="ml-3 inline-flex rounded-md shadow">
                  <a
                    href="https://app.formdr.com/practice/MjQzMTc=/form/SUF85F_ASI71ZhQTBSBIXapRI3U5eiDF"
                    target="_blank" rel="noreferrer"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-primary-600 hover:bg-primary-50"
                  >
                    Go to Portal
                  </a>
                </div>
              </div>
            </div>


            <div className="flow-root rounded-lg px-6">
              <div className="-mt-6">
                <div className="relative mt-12 sm:mx-auto sm:max-w-lg lg:col-span-6 lg:mx-0 lg:mt-0 lg:flex lg:max-w-none lg:items-center">
                  <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                    <iframe className="lg:w-96 h-80"
                      src="https://www.youtube.com/embed/wmS_q6gImn4"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
                    </iframe>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>

        <div className="bg-primary-400">
          <div className="mx-auto max-w-7xl py-12 px-6 lg:flex lg:items-center lg:justify-between lg:py-6 lg:px-8">
            <div className="sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:text-left">
              <h1>
                <span className="text-4xl font-bold font-bebas tracking-wide text-gray-900 sm:text-2xl lg:text-3xl">
                  <span className="block text-success-800">3. Review, Enroll, get started</span>
                </span>
              </h1>
              <p className="mt-3 text-base text-white sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                Get your Plan Comparison and Cost Analysis to make the best health benefits decision. You’ll get access to 12+ Plan Levels and allow your employees to choose their coverage based on their needs.
              </p>
            </div>
            <div className="relative mt-12 sm:mx-auto sm:max-w-lg lg:col-span-6 lg:mx-0 lg:mt-0 lg:flex lg:max-w-none lg:items-center">
              <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                <img
                  className="w-full"
                  src={Med2}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>






        <div className="relative bg-white py-24 sm:py-32 lg:py-12">
          <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
            <p className="mt-1 text-4xl font-bold font-bebas tracking-wide text-primary-400 sm:text-5xl lg:text-5xl">
              Why choose us?
            </p>
            <div className="mt-12">
              <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
                <div className="pt-6">
                  <div className="flow-root rounded-lg bg-white px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-xl bg-white text-primary-400 p-3">

                          <GiPiggyBank className="h-36 w-36" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">Save Time + Money</h3>
                      <p className="mt-5 text-base leading-7 text-gray-600">Find a cost-effective solution to providing health insurance at renewal and in comparison to your current provider.</p>
                    </div>
                  </div>
                </div>

                <div className="pt-6">
                  <div className="flow-root rounded-lg bg-white px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-xl bg-white text-success-800 p-3">

                          <IoSettingsOutline className="h-36 w-36" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">Customizable Options</h3>
                      <p className="mt-5 text-base leading-7 text-gray-600">Get access to 12+ different plan levels to ensure you and your employees are getting coverage based on your needs, first and foremost.</p>
                    </div>
                  </div>
                </div>

                <div className="pt-6">
                  <div className="flow-root rounded-lg bg-white px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-xl bg-white text-danger-500 p-3">

                          <ImClock className="h-36 w-36" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">Quick Turnaround</h3>
                      <p className="mt-5 text-base leading-7 text-gray-600">We work with you to make sure quotes are delivered in 48 hours** to ensure you and your employees stay covered.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </main>
      <p className="mt-10 text-center text-xs leading-5 text-gray-500"> **Individual plans may take more time due to carrier guidelines. All quote turnarounds are dependent on the carrier of your choice.</p>
      <p className="mt-2 text-center text-xs leading-5 text-gray-500">&copy; All Community Health Benefits are managed by CoreLife Benefits, not managed or affiliated with COhatch or any of its subsidiaries.</p>
    </>
  );
}